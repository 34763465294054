.pagination {
  display: flex;
  align-items: center;
  margin-top: 10px;
  /* margin-bottom: 40px; */
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  list-style: none;
  user-select: none;
}
.pagination .page-item {
  min-width: 25px;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px;
  transition: all 0.3s;
}
.pagination .page-item:hover {
  color: #069a77;
  /* background: #f4f4f4; */
  cursor: pointer
}
.pagination .page-item.active {
  padding: 5px;
  color: #fff;
  background: #069a77;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.pagination .page-item.arrow-icon {
  position: relative;
  z-index: 1;
  font-size: 35px;
  transform: translateY(-2px);
  transition: all 0.3s;
}
.pagination .page-item.arrow-icon:after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: -1;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pagination .page-item.arrow-icon:hover {
  color: #069a77;
  background: transparent;
}
/* .pagination .page-item.arrow-icon:hover:after {
  background: #f4f4f4;
} */
.pagination .page-item.arrow-icon.disabled {
  cursor: not-allowed;
  color: rgba(155, 155, 155, 0.3);
}
.pagination .page-item.arrow-icon.disabled:hover {
  background: transparent;
}