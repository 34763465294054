:root {
  --pramary: #ffffff;
  --secondary: #009688;
  --tercary: #000000;
  --orange: #f89617;
  --red: #f54130;
  --text: #4e4e4e;
  --footer-form-bg: #979797;
  --footer-bg: #2c2c2c;
  --clip-paths-space: -45px;
  --font-regular: ProximaNova;
  --font-bold: ProximaNovaBold;
}

p {
  margin: 0;
}
html {
  scroll-behavior: smooth;
}
img {
  max-width: 100%;
}
.social_media_icon {
  width: 46px;
  height: 46px;
  border: 1px solid var(--pramary);
  border-radius: 50px;
  color: var(--pramary);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 200ms;
}
.social_media_icon:hover {
  background-color: #f1f8ff;
  color: #000;
}
.social_media_icon > * {
  font-size: 14px !important;
}
.landing-btn {
  padding: 10px;
  border: none;
  font-family: var(--font-bold);
  background-color: transparent;
  font-size: 16px;
  /* border-radius:15px; */
  color: var(--pramary);
  transition: all ease 200ms !important;
  transform: scale(1) !important;
}
.landing-btn:hover {
  opacity: 0.9 !important;
  /* transform: scale(.9) !important; */
}
.bg-color-orange {
  background-color: var(--orange);
}
.bg-color-red {
  background-color: var(--red);
}
.bg-color-green {
  background-color: var(--secondary);
}
.d-flex {
  display: flex;
}
.gap-1 {
  gap: 0.5rem;
}
.gap-2 {
  gap: 1rem;
}
.gap-3 {
  gap: 1.5rem;
}
.gap-4 {
  gap: 2rem;
}
.header_container {
  background-color: var(--pramary);
  padding: 15px 0;
}
.landing_page_container {
  max-width: 1200px;
  margin: auto;
  padding: 0 15px;
}
.logo-pane {
  font-size: 25px;
  font-family: var(--font-bold);
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
}
.logo-green {
  color: var(--secondary);
}
.logo-orange {
  color: var(--orange);
}
.nav_items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header {
  display: flex;
  justify-content: space-between;
}
.nav_link {
  font-family: var(--font-bold) !important;
  font-size: 15px;
}
.nav_link.active {
  color: var(--secondary);
  border-bottom: 1px solid var(--orange);
}

/* ****************** banniere container ************* */
.banniere_container {
  height: 90vh;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./assets/img/Section.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banniere_container h1 {
  color: var(--pramary);
  font-size: 40px;
  font-family: var(--font-bold);
}
.banniere_container p {
  color: var(--pramary);
  max-width: 555px;
  font-family: var(--font-regular);
}
.banniere-bg-p {
  font-family: var(--font-bold) !important;
  background-color: var(--orange);
  padding: 5px;
  text-transform: uppercase;
  font-size: 15px;
  width: max-content;
}
.navbar-toggler {
  border: none !important;
}
.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* ************** Context section **************** */
.section_title {
  text-align: center;
  text-transform: uppercase;
  font-size: 25px;
  font-family: var(--font-bold);
  color: var(--secondary);
}
.h2_underline {
  position: relative;
  width: 200px;
  height: 2px;
  background-color: var(--orange);
  margin: 26px auto;
}
.h2_dots_container {
  position: absolute;
  left: 45%;
  top: -7px;
  text-align: center;
  width: 20px;
  height: 15px;
  border-radius: 50px;
  background-color: var(--pramary);
  display: flex;
  align-items: center;
  justify-content: center;
}
.h2_dots {
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: var(--orange);
  margin: auto;
}
.section_title_para {
  color: var(--text);
  font-size: 14px;
  text-align: center;
  max-width: 900px;
  margin: auto;
}

/* ***************** clip path **************** */
.clip_paths_container {
  margin-top: 7rem;
  display: flex;
  justify-content: center;
}
.clip_path {
  width: 400px;
  height: 195px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  -webkit-clip-path: polygon(
    20% 0%,
    80% 0%,
    100% 43%,
    100% 46%,
    80% 100%,
    20% 100%,
    0 46%,
    0 42%
  );
  clip-path: polygon(
    20% 0%,
    80% 0%,
    100% 50%,
    100% 50%,
    80% 100%,
    20% 100%,
    0 50%,
    0 50%
  );
  color: var(--pramary);
}
.clip_path_title {
  max-width: 200px;
  text-align: center;
  font-family: var(--font-bold);
  font-size: 16px;
  text-transform: uppercase;
}
.clip_path_red {
  background-color: var(--red);
  margin-left: var(--clip-paths-space);
}
.clip_path_orange {
  background-color: var(--orange);
  margin-right: var(--clip-paths-space);
}
.clip_path_green {
  margin-top: -100px;
  background-color: var(--secondary);
}
.clip_path ul {
  margin-top: 0.5rem;
  padding-left: 1.5rem;
  max-width: 210px;
}
.clip_path_list {
  list-style: disc;
  text-transform: uppercase;
  font-size: 12px;
  font-family: var(--font-bold);
}

/* **************** differents acteurs section ************ */
.differents_acteurs_container {
  background-image: url("./assets/img/bg-paper.png");
  min-height: 500px;
}
/* **************** tab item *************** */
.tab_item_title {
  font-family: var(--font-bold);
  font-size: 18px;
}
.tab_item_title,
.tab_item_text {
  color: var(--text);
}

/* *************** objectifs section *************** */
.objectif_card_item {
  color: var(--pramary);
  text-transform: uppercase;
  font-family: var(--font-bold);
}
/* ************** les chiffres section ************* */
.les_chiffres_section {
  background-color: #f1f8ff;
}
.les_chiffres_card_item {
  height: 150px !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.les_chiffres_card_item span {
  font-family: var(--font-bold);
  color: var(--secondary);
}
.chiffre_card_item_text {
  font-family: var(--font-bold) !important;
  font-size: 12px;
  color: var(--text) !important;
  text-transform: uppercase;
}

/* ************* contactez-nous ************ */
.laptop_bg_image {
  /* min-height: 500px;
    background-image: url('./assets/img/laptop.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; */
  background-color: var(--secondary);
}

/* *************** footer section ************** */
.footer_section {
  background-color: var(--footer-bg);
  color: var(--pramary);
}
.footer_section h5 {
  font-family: var(--font-bold);
  text-transform: uppercase;
}
.footer_title {
  font-family: var(--font-bold);
  font-size: 17px;
}
.footer_text {
  font-family: var(--font-bold) !important;
}
.footer_form {
  background-color: var(--text);
  padding: 1.5rem;
  margin-top: -20px;
}
.footer_input {
  padding: 10px;
  background-color: var(--pramary);
  color: var(--footer-form-bg);
  border: none;
  width: 100%;
  border-radius: 5px;
}
.footer_form_btn {
  background-color: var(--red);
  border: none;
  color: var(--pramary);
  position: absolute;
  right: 0;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.footer_text_top {
  color: var(--footer-form-bg);
  font-size: 14px;
}
.form_text {
  font-size: 12px;
  color: var(--pramary);
  margin-top: 15px;
}

@media screen and (max-width: 770px) {
  .clip_paths_container {
    margin-top: 1.5rem;
    flex-direction: column;
    align-items: center;
  }
  .clip_path_red {
    margin: 5px 0;
  }
  .clip_path_orange {
    margin: 5px 0;
  }
  .clip_path_green {
    margin: 5px 0;
  }
}
@media screen and (max-width: 576px) {
  .footer_form {
    margin-top: 5px;
  }
}
@media screen and (max-width: 450px) {
  .clip_path {
    width: 300px;
  }
  .clip_path_title {
    font-size: 14px;
  }
  .clip_path_list {
    font-size: 10px;
  }
}

/* Partie Avantages */

ul.advantage {
  font-family: var(--font-bold);
  font-size: 0.8rem;
}

/* Fixed Navbar */

.navbar {
  position: relative;
  z-index: 999;
  height: 62px;
  width: 100%;
  background-color: white;
}

.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
}
