@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"),
    url("../fonts/ProximaNova/ProximaNova-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNovaSemibold";
  src: local("ProximaNovaSemibold"),
    url("../fonts/ProximaNova/ProximaNova-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNovaBold";
  src: local("ProximaNovaBold"),
    url("../fonts/ProximaNova/ProximaNova-Bold.ttf") format("truetype");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: ProximaNova, sans-serif;
  line-height: 1.6;
  font-size: 18px;
  background-color: #f6f6f9 !important;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

.form-control:focus,
select:focus {
  box-shadow: none !important;
}

.white-bg {
  background: #ffffff !important;
  border: 1px solid #ebe7e7;
  border-radius: 5px;
}

.offreVide {
  color: #009688;
  font-size: 18px;
  font-family: ProximaNova, sans-serif;
  font-weight: bold;
}

.required::after {
  content: "*";
  color: #f44336;
  margin-left: 4px;
  font-size: 16px;
}

a:hover {
  text-decoration: none;
  color: initial;
}

.btn {
  font-family: ProximaNovaSemibold;
  font-size: 14px;
}

.h-theme {
  color: #009688;
  font-family: ProximaNova, sans-serif;
  font-style: normal;
}
.btn-red {
  background: #f44336;
  color: white;
  border: 1px solid #f44336 !important;
}

.btn-red:hover {
  background: #fff;
  color: #f44336;
  border: 1px solid #f44336 !important;
}

.btn-add {
  background: #009688;
  color: white;
}

.btn-add:hover {
  background: #009688;
  color: white;
}

.btn-import {
  background: #ff9800;
  color: white;
}

.btn-import:hover {
  background: #ff9800;
  color: white;
}

.btn-apply {
  background: #009688;
  color: #fff;
  font-family: ProximaNovaBold;
  /* width: 200px !important; */
}

.btn-apply:hover {
  background: #fff;
  color: #009688;
  border: 1px solid #009688;
}

.btn-edit {
  background: #f6f6f9;
}

.btn-edit:hover {
  background: lightgrey;
}

.btn-pdf {
  background: #f6f6f9;
  border: 1px solid black;
}

.btn-pdf:hover {
  background: lightgrey;
  border: 1px solid black;
}

.bakeli-color {
  font-family: ProximaNova, sans-serif;
  font-style: normal;
  font-family: ProximaNovaBold;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ff9800;
}
.carriere-color {
  font-family: ProximaNova, sans-serif;
  font-style: normal;
  font-family: ProximaNovaBold;
  font-size: 18px;
  line-height: 22px;
  /* text-align: center; */
  text-transform: uppercase;
  color: #009688;
  padding-left: 3%;
}

.link {
  text-decoration: underline;
  color: dodgerblue;
  cursor: pointer;
  font-weight: 500;
}

.niveauPassword {
  margin-top: 1rem;
  font-weight: bold;
}

/* Cards styles */

.offresCard img {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.loader_heigth {
  height: 6rem;
}

.offresCard span {
  font-size: 16px;
  color: #808080;
  font-weight: 400;
}
.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

/* Filter component styles */
.search_filter_container {
  background: #fff;
  margin: 20px 0;
  padding: 25px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
  box-sizing: border-box;
}

.search_input {
  border: none;
  /* padding: 5px; */
  font-size: 20px;
  font-family: ProximaNova;
  font-weight: 600;
  height: 50px;
  width: 100%;
}

/* .search_input::placeholder {
  color: #000000;
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 400;
} */

.search_filter_icon {
  font-size: 24px;
  color: #000000;
}

.reset_filter_icon {
  cursor: pointer;
  color: red;
}

.search_filter_select {
  border: none;
  height: 100%;
  color: #000000;
  font-size: 16px;
  font-family: ProximaNova;
  font-weight: 600;
}

/* Avatar Initials */
#initials {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 50px; */
  width: 40px;
  height: 40px;
  background-color: #009688;
  border-radius: 50%;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

/* EXPERIENCE TYPE STYLES */
.cdi {
  background: #009688;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.stage {
  background: #ff9800;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.cdd {
  background: lightgray;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}

.myCustomDropdown {
  position: absolute;
  z-index: 9999;
}

/* For Spinners */
.spinner {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #000000 94%, #0000) top/3.8px 3.8px
      no-repeat,
    conic-gradient(#0000 30%, #000000);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 3.8px), #000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 3.8px),
    #000 0
  );
  animation: spinner-c7wet2 0.6s infinite linear;
}

p {
  font-weight: 300 !important;
  font-size: 0.8rem;
}

@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}

/* Filepond */
.filepond--drop-label {
  color: #4c4e53;
}

.filepond--label-action {
  text-decoration-color: #babdc0;
}

.filepond--panel-root {
  background-color: #edf0f4;
}

.filepond--root--avatar {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

/* Styles progress */
/* progress[value] {
  width: 250px;
  height: 20px;
} */

progress {
  border-radius: 50px;
  background-color: #ff9800;
  width: 250px;
  height: 0.3rem;
}

progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 30px;
  height: 0.3rem;
  background: #e5e4e7;
  border: none;
}

progress[value]::-webkit-progress-bar {
  border: none;
  border-radius: 20px;
  background: #e5e4e7;
  border: 1px solid #cdc7c2;
  height: 0.3rem;
}

progress[value]::-webkit-progress-value {
  border-radius: 20px;
  background: #ff9800;
}

progress[value]::-moz-progress-bar {
  border-radius: 20px;
  background: #ff9800;
}

.btnCompleteProfile {
  background: #009688 !important ;
  padding: 5px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bolder;
  color: white;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
  0% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #000;
  }
  33% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #0002;
  }
  100% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #000;
  }
}
